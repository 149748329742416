<template>
  <div class="change-king-tag-box" v-if="showPopup">
    <a-modal
        :width="700"
        v-model="showPopup"
        title="修改标王截拍时间"
        ok-text="确认"
        cancel-text="取消"
        @cancel="showPopup = false"
        @ok="handleConfirm"
    >
      <div class="model-flex">
        <a-select
            allowClear
            v-model="squareId"
            :dropdownMatchSelectWidth="false"
            :filter-option="untils.filterOption"
            showSearch
            style="width: 300px;"
            class="header-item"
            @search="getSquareList"
            placeholder='请输入场次名称进行查找'
        >
          <a-select-option
              v-for="item in squareList"
              :key="item.value"
              :value='item.value'>{{ item.name + `【${item.num}】` }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>
</template>
<script>
import untils from "@/untils"
export default {
  data() {
    return {
      untils,
      showPopup: false,
      auctionIds: '',
      squareId: undefined,
      squareList: [],
      squareParams: {
        pageNum: 1,
        pageSize: 10,
        periodName: undefined
      }
    }
  },
  methods: {
    /** 获取店铺列表 */
    async getSquareList(userName) {
      let params = JSON.parse(JSON.stringify(this.squareParams))
      params.periodName = userName
      const res = await this.axios('/dq_admin/productAuctionPeriod/list',{params: params})
      const {records } = res.data
      if (records && records.length) {
        this.squareList = records.map(el => {
          return {name: el.periodName, value: el.id, num: el.periodNum}
        })
      }
    },
    /**  显示弹窗 */
    async show(ids) {
      this.auctionIds = ids
      await this.getSquareList()
      this.showPopup = true
    },
    async handleConfirm() {
      if (!this.squareId) return this.$message.warn('请选择场次')
      const res = await this.axios.post("/dq_admin/productAuctionPeriod/periodAddProduct", {
        periodId: this.squareId,
        productIds: this.auctionIds
      })
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.$emit('successEdit')
      this.showPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>