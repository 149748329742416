<template>
  <div class="change-king-tag-box" v-if="showPopup">
    <a-modal
        :width="700"
        v-model="showPopup"
        title="修改标王截拍时间"
        ok-text="确认"
        cancel-text="取消"
        @cancel="showPopup = false"
        @ok="handleConfirm"
    >
      <div class="model-flex">
        <a-date-picker
            :defaultValue="moment(defaultValueDate, 'YYYY-MM-DD')"
            @change="onChangeDatePicker"
            :disabledDate="disabledDate"
            :locale="locale"
            placeholder="请选择日期"
        />
        <a-cascader
            :options="options"
            :default-value="[defaultValueTime[0] + '', defaultValueTime[1] + '']"
            placeholder="请选择时间"
            @change="onChangeTimePicker"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import moment from 'moment'
import {timeList} from "@/views/cmsPage/productManage/auctionAboutList/_services";
export default {
  data() {
    return {
      locale,
      showPopup: false,
      auctionId: '',
      dateString: '',
      timeString: '',
      dateNew: new Date(),
      options: timeList,
      endTime: '',
      defaultValueDate: '',
      defaultValueTime: []
    }
  },
  methods: {
    moment,
    /** 禁止选择的日期 */
    disabledDate(current) {
      let hours = this.dateNew.getHours()
      if (hours >= 17) {
        return current < moment().subtract(1,'days')
        // return current && (moment(current).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') || current < moment().subtract('day'))
      } else {
        return current < moment().subtract(1,'days')
      }
    },
    /** 选则日期 */
    onChangeDatePicker(date, dateString) {
      this.dateString = dateString
    },
    /** 选择时间 */
    onChangeTimePicker(e) {
      if (e && e.length) {
        this.timeString = e[0] + ':' + e[1]
      } else {
        this.timeString = ''
      }
    },
    /**  显示弹窗 */
    show(data) {
      this.auctionId = data.id
      this.endTime = data.endTime
      this.defaultValueDate = this.endTime.substring(0, 11)
      this.defaultValueTime = this.endTime.substring(11).split(':')
      this.dateString = this.defaultValueDate
      this.timeString = this.defaultValueTime[0] + ':' + this.defaultValueTime[1]
      this.showPopup = true
    },
    async handleConfirm() {
      if (!this.dateString) return this.$message.warn('请选择日期')
      if (!this.timeString) return this.$message.warn('请选择时间')
      const endTimeString = this.dateString + ' ' + (this.timeString + ':' + '00')
      const res = await this.axios.post("/dq_admin/product/perfectProductUpdateEndTime", {
        id: this.auctionId,
        endTime: endTimeString
      })
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.$emit('successEdit')
      this.showPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>