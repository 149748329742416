var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPopup
    ? _c(
        "div",
        { staticClass: "change-king-tag-box" },
        [
          _c(
            "a-modal",
            {
              attrs: {
                width: 700,
                title: "修改标王截拍时间",
                "ok-text": "确认",
                "cancel-text": "取消"
              },
              on: {
                cancel: function($event) {
                  _vm.showPopup = false
                },
                ok: _vm.handleConfirm
              },
              model: {
                value: _vm.showPopup,
                callback: function($$v) {
                  _vm.showPopup = $$v
                },
                expression: "showPopup"
              }
            },
            [
              _c(
                "div",
                { staticClass: "model-flex" },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "header-item",
                      staticStyle: { width: "300px" },
                      attrs: {
                        allowClear: "",
                        dropdownMatchSelectWidth: false,
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请输入场次名称进行查找"
                      },
                      on: { search: _vm.getSquareList },
                      model: {
                        value: _vm.squareId,
                        callback: function($$v) {
                          _vm.squareId = $$v
                        },
                        expression: "squareId"
                      }
                    },
                    _vm._l(_vm.squareList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [
                          _vm._v(
                            _vm._s(item.name + "【" + item.num + "】") + " "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }